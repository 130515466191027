<template>
	<PageLayout :title="$t('confirm-account.title')" hide-back-button>
		<Loader v-if="loading" />

		<p v-if="!loading && !success">
			{{ $t('confirm-account.failed') }}
		</p>
		<p v-if="!loading && success">
			{{ $t('confirm-account.success') }}
		</p>

		<CButton primary type="submit" @click="goToLogin()">
			{{ $t('actions.go-to-login') }}
		</CButton>
	</PageLayout>
</template>

<script>
import { router } from '@/main';
import apiClient from '../../api';
import CButton from '@/shared/cbutton/CButton';

const PageLayout = () => import('../page-layout/PageLayout');
const Loader = () => import('../../shared/loader/Loader');

export default {
	name: 'ConfirmAccount',
	components: {
		PageLayout,
		Loader,
		CButton,
	},
	data() {
		return {
			loading: true,
			success: false,
		};
	},
	beforeMount() {
		const { token } = this.$route.params;
		this.confirmAccount(token);
	},
	methods: {
		goToLogin() {
			router.replace('/login');
		},
		async confirmAccount(verifyToken) {
			try {
				const { redirectUrl } = await apiClient.verify(verifyToken);
				this.success = true;

				if (redirectUrl) {
					window.setTimeout(() => {
						if (redirectUrl.startsWith('http')) {
							window.location = redirectUrl;
						} else {
							router.replace(redirectUrl);
						}
					}, 1000);
				}
			} catch (e) {
				console.error(e);
				this.success = false;
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
